import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

export default function ToastMsg({ title, subTitle, type }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (title && subTitle) {
      setShow(true);
    }
  }, [title, subTitle, type]);

  return (
    <div className="toast-main">
      <Toast
        className={`bg-${type}`}
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide={true}
      >
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{subTitle}</Toast.Body>
      </Toast>
    </div>
  );
}
