import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Navbar,
  Container,
  Nav,
  Form,
  FormControl,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { adminUserId } from "../utils";

export default function ContentWithNavBar({ children, page }) {
  const [error, setError] = useState("");
  const { logout, currentUser } = useAuth();
  const history = useHistory();
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(currentUser?.uid === adminUserId);
  }, [currentUser?.uid]);

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            Welcome {currentUser?.displayName} {isAdmin ? "(Admin)" : ""}
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll"></Navbar.Collapse>
          <div className="d-flex">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link active={page === "dashboard"} href="/">
                {isAdmin ? "Users" : "Dashboard"}
              </Nav.Link>
              <Nav.Link
                active={page === "update-profile"}
                href="/update-profile"
              >
                Update Profile
              </Nav.Link>
            </Nav>
            <Button variant="link" onClick={handleLogout}>
              Log Out
            </Button>
          </div>
        </Container>
      </Navbar>
      {error && <Alert variant="danger">{error}</Alert>}
      {children}
    </>
  );
}
