import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import BarChart from "react-easy-bar-chart";
import { getAverageCalories, getQuery } from "../utils";

const calories = db.collection("calories");

export default function Bar() {
  const [data, setData] = useState([]);
  const [averageCaloriesPerUser, setAverageCaloriesPerUser] = useState([]);

  const getData = async () => {
    let [last7Days, lastWeek, thisWeek] = await Promise.all([
      getQuery(calories).get(),
      getQuery(calories, 3).get(),
      getQuery(calories, 2).get(),
    ]);

    setAverageCaloriesPerUser([
      {
        title: "Last 7 Days",
        value: getAverageCalories(last7Days.docs.map((doc) => doc.data())),
        color: "#196f3d",
      },
      {
        title: "Last Week",
        value: getAverageCalories(lastWeek.docs.map((doc) => doc.data())),
        color: "#a93226",
      },
      {
        title: "This Week",
        value: getAverageCalories(thisWeek.docs.map((doc) => doc.data())),
        color: "#1f618d",
      },
    ]);

    setData([
      {
        title: "Last 7 Days",
        value: last7Days.size,
        color: "#196f3d",
      },
      {
        title: "Last Week",
        value: lastWeek.size,
        color: "#a93226",
      },
      {
        title: "This Week",
        value: thisWeek.size,
        color: "#1f618d",
      },
    ]);

  };

  useEffect(() => {
    getData();
  }, []);

  if (!data.length) return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <BarChart
            xAxis="Number of added entries "
            yAxis=""
            height={250}
            width={300}
            data={data}
          />
        </div>
        <div className="col-sm">
          <BarChart
            xAxis="Average number of calories added per user"
            yAxis=""
            height={250}
            width={300}
            data={averageCaloriesPerUser}
          />
        </div>
      </div>
    </div>
  );
}
