import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { db } from "../firebase";
import ContentWithNavBar from "./ContentWithNavBar";
import { MdNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

import { adminUserId } from "../utils";
import Bar from "./Bar";

const doc = db.collection("users");

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const watcher = doc.onSnapshot(
      (docSnapshot) => {
        setLoading(false);
        setError("");
        if (docSnapshot && docSnapshot.docs) {
          const data = docSnapshot.docs.map((doc) => doc.data());
          setUsers(data.filter((d) => d.uid !== adminUserId)); //Remove admin user from user list
        }
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      }
    );

    return () => watcher;
  }, []);

  return (
    <>
      <ContentWithNavBar page="dashboard">
        {error ? (
          <div className="d-flex justify-content-center dashboard-error">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        ) : (
          <>
            {loading ? (
              <div className="d-flex loading">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Container fluid>
                <>
                  <Bar />
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(({ uid, name, email }, index) => (
                        <tr key={"user" + uid}>
                          <td>{index + 1}</td>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>
                            <Link to={"/users-dashboard/" + uid}>
                              View Details <MdNavigateNext />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              </Container>
            )}
          </>
        )}
      </ContentWithNavBar>
    </>
  );
}
