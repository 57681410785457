import React, { memo } from "react";
import { useAuth } from "../contexts/AuthContext";
import { adminUserId } from "../utils";
import Dashboard from "./Dashboard";
import PrivateRoute from "./PrivateRoute";
import UpdateProfile from "./UpdateProfile";
import Users from "./Users";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser?.uid === adminUserId

  if (isAdmin) {
    return (
      <>
       <PrivateRoute exact path="/" component={Users} />
       <PrivateRoute
          exact
          path="/users-dashboard/:userId"
          component={Dashboard}
        />
        <PrivateRoute
          exact
          path="/users-dashboard/:userId/:filterId"
          component={Dashboard}
        />
        <PrivateRoute path="/update-profile" component={UpdateProfile} />
      </>
    );
  }

  return (
    <>
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/:filterId" component={Dashboard} />
      <PrivateRoute path="/update-profile" component={UpdateProfile} />
    </>
  );
};

export default memo(PrivateRoutes);
