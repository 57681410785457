import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { db, storage } from "../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { deleteFileFromStorage, firebaseImagesDir, getDate } from "../utils";

const doc = db.collection("calories");
export default function AddEditModal({
  userId,
  newlyAddedEntry,
  editEntry,
  unsetEditEntry,
  btn,
}) {
  const [show, setShow] = useState(false);
  const [isSubmit, setFormSubmit] = useState(false);

  const [datetime, setDateTime] = useState();
  const [food, setFood] = useState("");
  const [calories, setCalories] = useState("");
  const [file] = useState();
  const [imageForPreview, setImageForPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const fileRef = useRef();

  const handleClose = () => {
    setShow(false);
    unsetEditEntry && unsetEditEntry();
    setFood("");
    setCalories("");
    setDateTime("");
    setImageForPreview("");
    fileRef.current.value = "";
    setFormSubmit(false);
    setProgressPercent(0);
  };
  const handleShow = () => {
    setShow(true);
    setDateTime(moment().format("yyyy-MM-DDTHH:mm"));
  };

  useEffect(() => {
    if (editEntry) {
      const { food, calories, datetime, foodImage } = editEntry;
      setFood(food);
      setCalories(calories);
      setDateTime(moment(getDate(datetime)).format("yyyy-MM-DDTHH:mm"));
      setImageForPreview(foodImage);
      setShow(true);
    }
  }, [editEntry]);

  function handleSubmit(e) {
    e.preventDefault();
    setFormSubmit(true);
    if (datetime && food && calories) {
      setLoading(true);
      if (imageForPreview) {
        if (
          editEntry?.foodImage &&
          !editEntry?.foodImage.includes("https://")
        ) {
          //remove old image
          deleteFileFromStorage(editEntry.foodImage);
        }
        uploadImage();
      } else {
        saveData();
      }
    }
  }

  const saveData = (imgUrl = "") => {
    const finalData = {
      datetime: new Date(moment(datetime)),
      food,
      calories: Number(calories) || 0,
      userId,
    };

    if (imgUrl) {
      finalData.foodImage = imgUrl;
    }

    if (editEntry) {
      //handle Edit

      if (imageForPreview) {
        finalData.foodImage = imageForPreview;
      }

      if (editEntry.foodImage !== imageForPreview) {
        //remove old image
        deleteFileFromStorage(editEntry.foodImage);
      }

      doc
        .doc(editEntry.id)
        .set(finalData)
        .then(() => {
          setLoading(false);
          handleClose();
          if (!editEntry) newlyAddedEntry(finalData);
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => setLoading(false));
    } else {
      //Handle Add
      doc
        .add(finalData)
        .then(() => {
          setLoading(false);
          handleClose();
          newlyAddedEntry(finalData);
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const uploadImage = () => {
    const f = fileRef?.current?.files?.length && fileRef?.current?.files[0];
    if (!f) {
      saveData();
      return;
    }

    const storageRef = ref(
      storage,
      `${firebaseImagesDir}/${new Date().getTime()}-${f.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, f);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => {
        saveData();
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          saveData(downloadURL);
        });
      }
    );
  };

  const handleChangeImage = (e) => {
    setImageForPreview(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      {btn ? (
        <div onClick={handleShow}>{btn}</div>
      ) : (
        <Button
          onClick={handleShow}
          className="btn btn-success btn-lg float-right mt-3 mb-3"
        >
          Add New Entry
        </Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex">
              Add new calorie intake
              {loading ? (
                <div>
                  <div
                    className="spinner-border add-entry-loader"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <div className="progress image-upload-progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: progressPercent + "%" }}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <Modal.Body>
            <Form.Group id="datetime">
              <Form.Label className={isSubmit && !datetime && "text-danger"}>
                Date Time
              </Form.Label>
              <Form.Control
                type="datetime-local"
                value={datetime}
                required
                onChange={(e) => setDateTime(e.target.value)}
              />
              {isSubmit && !datetime ? (
                <div>
                  <small className="text-danger">Please enter date time.</small>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group id="food-name">
              <Form.Label
                value={food}
                className={isSubmit && !food && "text-danger"}
              >
                Food name
              </Form.Label>
              <Form.Control
                type="text"
                value={food}
                onChange={(e) => setFood(e.target.value)}
                placeholder="Food name"
                className={isSubmit && !food && "is-invalid"}
              />
              {isSubmit && !food ? (
                <div>
                  <small className="text-danger">Please enter food name.</small>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group id="calories-taken">
              <Form.Label className={isSubmit && !calories && "text-danger"}>
                Calories
              </Form.Label>
              <Form.Control
                value={calories}
                onChange={(e) => setCalories(e.target.value)}
                className={isSubmit && !calories && "is-invalid"}
                type="number"
                placeholder="Calories"
              />
              {isSubmit && !calories ? (
                <div>
                  <small className="text-danger">
                    Please enter calories taken. {calories}
                  </small>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group id="calories-taken">
              <Form.Label>Add Image</Form.Label>
              <Form.Control
                value={file}
                ref={fileRef}
                onChange={handleChangeImage}
                type="file"
                placeholder="Calories"
              />
            </Form.Group>

            {imageForPreview ? (
              <div className="img-wrap">
                <span
                  onClick={() => {
                    setImageForPreview("");
                    fileRef.current.value = null;
                  }}
                  className="close"
                >
                  &times;
                </span>
                {imageForPreview ? (
                  <img
                    className="food-image"
                    alt="food"
                    src={imageForPreview}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading ? <div className="block-screen"></div> : null}
    </>
  );
}
