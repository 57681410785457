import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { db } from "../firebase";
import AddEditModal from "./AddEditModal";
import ContentWithNavBar from "./ContentWithNavBar";
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import {
  deleteFileFromStorage,
  toasts,
  getDate,
  calculateDayCalories,
  caloriesInADay,
  dateTimeFormat,
  caloriesStatusClass,
  getQuery,
  adminUserId,
} from "../utils";
import ToastMsg from "./ToastMsg";
import { useAuth } from "../contexts/AuthContext";
import { useHistory, useLocation, useParams } from "react-router-dom";

const doc = db.collection("calories");

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [calories, setCalories] = useState([]);
  const [toastData, setToastData] = useState({});
  const [editEntry, setEditEntry] = useState();
  const [dataDisplayMode, setDataDisplayMode] = useState(1);
  const { currentUser } = useAuth();
  const [userId, setUserId] = useState(currentUser?.uid);
  const params = useParams();
  const [userInfo, setUserInfo] = useState();

  const location = useLocation();
  const history = useHistory();

  const getUser = async (uid) => {
    const user = await db.collection("users").doc(uid).get();
    setUserInfo(user.data());
  };

  useEffect(() => {
    if (location?.pathname.includes("/users-dashboard/")) {
      if (currentUser?.uid === adminUserId) {
        const { userId } = params;
        setUserId(userId);
        getUser(userId);
      } else {
        history.push("/");
      }
    }
  }, [currentUser.uid, history, location, params]);

  useEffect(() => {
    setDataDisplayMode(Number(params.filterId || 1));
  }, [location.pathname, params]);

  const changeFilter = useCallback((type) => {
    if (location?.pathname.includes("/users-dashboard/")) {
      const { userId } = params;
      history.replace(`/users-dashboard/${userId}/${type}`);
    } else {
      history.replace(`/${type}`);
    }
  }, [history, location.pathname, params]);

  const newlyAddedEntry = (data) => {
    setToastData({
      ...toasts.newEntry,
      title: data.food,
      type: "success",
    });
  };

  useEffect(() => {
    const watcher = getQuery(
      doc.where("userId", "==", userId || currentUser?.uid),
      dataDisplayMode
    ).onSnapshot(
      (docSnapshot) => {
        setLoading(false);
        setError("");
        if (docSnapshot && docSnapshot.docs) {
          const data = docSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setCalories(
            data
              .map((d) => ({
                ...d,
                consumedCalories: calculateDayCalories(d, data),
              }))
              .reverse()
          );
          setDataDisplayMode(Number(params.filterId || 1));
        }
        docSnapshot.docChanges().forEach(function (change) {
          const data = change.doc.data();
          if (change.type === "modified") {
            setToastData({
              ...toasts.modified,
              title: data.food,
              type: "warning",
            });
          }
          if (change.type === "removed") {
            setToastData({
              ...toasts.deleteEntry,
              title: data.food,
              type: "danger",
            });
          }
        });
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      }
    );
    return () => watcher;
  }, [userId, dataDisplayMode, currentUser.uid, params.filterId, changeFilter]);

  const deleteEntry = (id, fileUrl = "") => {
    doc
      .doc(id)
      .delete()
      .then(() => {
        deleteFileFromStorage(fileUrl);
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ToastMsg {...toastData} />
      <ContentWithNavBar page="dashboard">
        {error ? (
          <div className="d-flex justify-content-center dashboard-error">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        ) : (
          <>
            {loading ? (
              <div className="d-flex loading">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {userInfo ? (
                  <>
                    <Container fluid>
                      <h2 className="mt-5 mb-5">
                        Viewing
                        <strong> {userInfo?.name}'s </strong> profile
                      </h2>
                    </Container>
                  </>
                ) : null}

                <Container fluid>
                  <Button
                    onClick={() => changeFilter(1)}
                    className={`btn btn-lg mt-3 mb-3 ${
                      dataDisplayMode === 1 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    Last 7 Days
                  </Button>

                  <Button
                    onClick={() => changeFilter(2)}
                    className={`btn btn-lg ml-3  mt-3 mb-3 ${
                      dataDisplayMode === 2 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    This Week
                  </Button>

                  <Button
                    onClick={() => changeFilter(3)}
                    className={`btn btn-lg ml-3  mt-3 mb-3 ${
                      dataDisplayMode === 3 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    Last Week
                  </Button>

                  <Button
                    onClick={() => changeFilter(4)}
                    className={`btn btn-lg ml-3  mt-3 mb-3 ${
                      dataDisplayMode === 4 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    Today
                  </Button>

                  <Button
                    onClick={() => changeFilter(5)}
                    className={`btn btn-lg ml-3  mt-3 mb-3 ${
                      dataDisplayMode === 5 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    Yesterday
                  </Button>

                  <Button
                    onClick={() => changeFilter(6)}
                    className={`btn btn-lg ml-3  mt-3 mb-3 ${
                      dataDisplayMode === 6 ? "btn-primary" : "btn-light"
                    }`}
                  >
                    All
                  </Button>

                  <AddEditModal
                    editEntry={editEntry}
                    userId={userId || currentUser?.uid}
                    unsetEditEntry={() => setEditEntry(undefined)}
                    newlyAddedEntry={newlyAddedEntry}
                  />
                  {!loading && calories.length ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Food</th>
                          <th>Calories</th>
                          <th>Calories Status</th>
                          <th>DateTime</th>
                          <th>Food Image</th>
                          <th>Edit/Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {calories.map(
                          (
                            {
                              id,
                              food,
                              calories,
                              datetime,
                              foodImage,
                              consumedCalories,
                            },
                            index
                          ) => (
                            <tr key={"food" + id}>
                              <td>{index + 1}</td>
                              <td>{food}</td>
                              <td>{calories}</td>
                              <td
                                className={caloriesStatusClass(
                                  caloriesInADay,
                                  consumedCalories
                                )}
                              >
                                {caloriesInADay} / {consumedCalories}
                              </td>
                              <td>
                                {moment(getDate(datetime)).format(
                                  dateTimeFormat
                                )}
                              </td>
                              <td>
                                {foodImage ? (
                                  <img
                                    src={foodImage}
                                    className="food-image-in-list"
                                    alt="foodImage"
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <span
                                  style={{ width: 50, display: "inline-block" }}
                                >
                                  <FaRegEdit
                                    onClick={() =>
                                      setEditEntry({
                                        id,
                                        food,
                                        calories,
                                        datetime,
                                        foodImage,
                                      })
                                    }
                                  />
                                </span>
                                <AiFillDelete
                                  onClick={() => deleteEntry(id, foodImage)}
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="d-flex loading">
                      <div className="no-data">
                        <h3 className="text-secondary text-center">
                          There is no data for the selected filter, you can add
                          new entry by clicking on the top right 'Add New Entry'
                          button.
                        </h3>
                      </div>
                    </div>
                  )}
                </Container>
              </>
            )}
          </>
        )}
      </ContentWithNavBar>
    </>
  );
}
