import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDYLBNJ-XxVJ2NYzX-RJyaRKaCxq30O70g",
  authDomain: "calorie-tracker-93df5.firebaseapp.com",
  projectId: "calorie-tracker-93df5",
  storageBucket: "calorie-tracker-93df5.appspot.com",
  messagingSenderId: "752774829441",
  appId: "1:752774829441:web:6d2072517d2e5dfdef8480",
  measurementId: "G-84RWP21HF2",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const db = firebase.firestore();
export const storage = getStorage(app);
export default app;
