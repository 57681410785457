import React, {  } from "react";
import {  Container } from "react-bootstrap";

export default function CardWrapper({children}) {
 
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="c-form-wrapper">
        {children}
        </div>
    </Container>
  );
}
