import { storage } from "./firebase";
import { ref, deleteObject } from "firebase/storage";
import moment from "moment";
moment.updateLocale("en", {
  week: {
    dow: 0, // 0 to 6 sunday to saturday
  },
});

export const adminUserId = "dpd7eBBVsgOv0i3wsVH7srsbd5v1";
export const firebaseImagesDir = "foodImages";
export const caloriesInADay = 2100;

export const deleteFileFromStorage = (fileUrl) => {
  if (fileUrl) {
    try {
      // Delete the file
      const img = decodeURI(fileUrl.split(`${firebaseImagesDir}%2F`)[1]).split(
        "?alt"
      )[0]; //get Image name
      const desertRef = ref(storage, `foodImages/${img}`);
      deleteObject(desertRef);
    } catch (e) {
      // Delete the file
      const img = decodeURI(fileUrl.split("foodImages%2F")[1]).split("?alt")[0]; //get Image name
      const desertRef = ref(storage, `foodImages/${img}`);
      deleteObject(desertRef);
    }
  }
};

export const toasts = {
  newEntry: {
    title: "New food entry",
    subTitle: "New food entry added.",
  },
  deleteEntry: {
    title: "Food entry deleted",
    subTitle: "Food entry deleted.",
  },
  modified: {
    title: "Food entry modified",
    subTitle: "Food entry modified.",
  },
};

export const dateFormat = "yyyy-MM-DD";
export const dateTimeFormat = "dddd, DD MMM yyyy hh:mm A";

export const getDate = (date) => {
  return new Date(date?.seconds * 1000);
};

export const getMomentDateOnly = (date) => {
  return moment(date).format(dateFormat);
};

export const calculateDayCalories = (obj, data) => {
  const thisDateCalories = data.filter(
    (d) =>
      getMomentDateOnly(getDate(obj?.datetime)) ===
      getMomentDateOnly(getDate(d?.datetime))
  );

  return thisDateCalories.reduce((a, d) => {
    return (a += Number(d?.calories || 0));
  }, 0);
};

export const caloriesStatusClass = (caloriesInADay, consumedCalories) => {
  if (caloriesInADay > consumedCalories) {
    return "bg-success";
  } else if (caloriesInADay === consumedCalories) {
    return "bg-warning";
  } else {
    return "bg-danger";
  }
};

export const getStartDate = (date) => new Date(moment(date + " 00:00"));
export const getEndDate = (date) => new Date(moment(date + " 23:59"));

export const getQuery = (doc, type = 1) => {
  const today = moment().format(dateFormat);

  switch (type) {
    case 2: //this week
      const first = moment().startOf("week").format(dateFormat);
      const last = moment().endOf("week").format(dateFormat);
      return doc
        .orderBy("datetime")
        .startAt(getStartDate(first))
        .endAt(getEndDate(last));

    case 3: //last week
      const lwFirst = moment()
        .startOf("week")
        .subtract(7, "days")
        .format(dateFormat);
      const lwLast = moment()
        .endOf("week")
        .subtract(7, "days")
        .format(dateFormat);

      return doc
        .orderBy("datetime")
        .startAt(getStartDate(lwFirst))
        .endAt(getEndDate(lwLast));

    case 4: //today
      return doc
        .orderBy("datetime")
        .startAt(getStartDate(today))
        .endAt(getEndDate(today));

    case 5: //Yesterday
      const yesterday = moment().day(-1).format(dateFormat);
      return doc
        .orderBy("datetime")
        .startAt(getStartDate(yesterday))
        .endAt(getEndDate(yesterday));

    case 6: //all data
      return doc.orderBy("datetime");

    default: //last 7 days
      const todayBefore7 = moment().day(-6).format(dateFormat);
      return doc
        .orderBy("datetime")
        .startAt(getStartDate(todayBefore7))
        .endAt(getEndDate(today));
  }
};

export const getAverageCalories = (data) => {
  const dataByUser = {};

  data.forEach(({ userId, calories }) => {
    if (!dataByUser[userId]) dataByUser[userId] = 0;
    dataByUser[userId] += calories;
  });

  const arr = [];
  Object.keys(dataByUser).map((key) => {
    arr.push(dataByUser[key]);
  });
  return arr.reduce((a, c) => (a += c)) / arr.length;
};
